import { Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { graphql } from "gatsby";
import React from "react";
import AvatarCard from "../components/AvatarCard";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    backgroundColor: theme.palette.common.light
  },
  teamAvatarTitle: {
    marginBottom: theme.spacing(8)
  },
  expertsContainer: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const OurTeamPage = ({ data }) => {
  const classes = useStyles();
  const founders = data.founders.nodes;
  const experts = data.experts.nodes;

  return (
    <Layout>
      <SEO
        title="Our Team"
        description="Our team is predominately made up of ex-bankers with 20-30 years of banking experience. "
      />
      <Section className={classes.heroContainer}>
        <Container>
          <Typography variant="h1" align="center">
            The Team
          </Typography>
        </Container>
      </Section>
      <Section>
        <Container>
          <Typography
            className={classes.teamAvatarTitle}
            variant="h3"
            component="h1"
          >
            Founders
          </Typography>
          <Grid container spacing={6}>
            {founders.map(founder => (
              <Grid key={founder.id} item xs={12} sm={6} md={4}>
                <AvatarCard
                  avatar={founder.avatar}
                  role={founder.role}
                  fullName={founder.fullName}
                  bio={founder.bio}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
      <Section className={classes.expertsContainer}>
        <Container>
          <Typography
            className={classes.teamAvatarTitle}
            variant="h3"
            component="h1"
          >
            Advisers
          </Typography>
          <Grid container spacing={6} component={Paper} elevation={0}>
            {experts.map(expert => (
              <Grid key={expert.id} item xs={12} sm={6} md={3}>
                <AvatarCard
                  avatar={expert.avatar}
                  role={expert.role}
                  fullName={expert.fullName}
                  bio={expert.bio}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    founders: allTeamMember(filter: { role: { regex: "/Founder/" } }) {
      nodes {
        ...TeamMemberWithAvatar
      }
    }
    experts: allTeamMember(
      filter: { role: { eq: "Commercial Finance Broker" } }
    ) {
      nodes {
        ...TeamMemberWithAvatar
      }
    }
  }
`;

export default OurTeamPage;
